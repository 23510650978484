<h1 mat-dialog-title>Extra security check</h1>
<mat-dialog-content>
    <div>
        <p>To access this page you are required to fill in the extra admin password.</p>
        <p>If you forgot it please contact the system administrator.</p>
        <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
            <mat-label>Fill in extra security check</mat-label>
            <input [formControl]="hash" matInput type="password" (keyup.enter)="closeDialog()" />
            @if (hash.hasError('secFailure')) {
                <mat-error>Security check failed</mat-error>
            }
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-between">
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button color="primary" mat-raised-button [disabled]="!success" [mat-dialog-close]="true">
        @if (success) {
            <mat-icon>check</mat-icon>
        } @else {
            <mat-icon>close</mat-icon>
        }
        Proceed
    </button>
</mat-dialog-actions>
