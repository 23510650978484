import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-admin-hash-dialog',
    standalone: true,
    // imports: [MatDialogModule, MatFormFieldModule, ReactiveFormsModule, MatButtonModule, MatInputModule],
    imports: [
        MatDialogContent,
        MatFormField,
        MatLabel,
        MatInput,
        MatButton,
        ReactiveFormsModule,
        MatError,
        MatDialogActions,
        MatDialogClose,
        MatIcon,
    ],
    templateUrl: './admin-hash-dialog.component.html',
    styleUrl: './admin-hash-dialog.component.scss',
})
export class AdminHashDialogComponent {
    constructor(private readonly dialogRef: MatDialogRef<AdminHashDialogComponent>) {
        this.hash.valueChanges.pipe(takeUntilDestroyed(), debounceTime(400)).subscribe(hash => {
            if (hash === this.checkWith) {
                this.success = true;
            } else {
                this.success = false;
                this.hash.setErrors({ secFailure: true });
                this.hash.markAsTouched();
            }
        });
    }
    hash = new FormControl('', { validators: [Validators.required] });
    success = false;
    checkWith?: string;

    closeDialog() {
        if (this.success && this.hash.valid) {
            this.dialogRef.close(this.success);
        }
    }
}
