import { inject } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, type CanActivateFn } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { AuthService } from '../auth.service';
import type { Customer } from '../types';
import { typeConverter } from '../util';
import { AdminHashDialogComponent } from './admin-hash-dialog/admin-hash-dialog.component';

/**
 * A guard function that protects routes based on admin hash protection.
 * @returns A promise that resolves to a boolean indicating whether the route can be activated.
 */
export const adminHashProtectionGuard: CanActivateFn = async (_route, _state) => {
    const authService = inject(AuthService);
    const firestore = inject(Firestore);
    const dialog = inject(MatDialog);
    const snack = inject(MatSnackBar);
    const router = inject(Router);
    const result = await adminHashProtection({ authService, firestore, dialog, snack, router });
    return result;
};

/**
 * Checks if the current user has the admin hash protection enabled.
 * If the admin hash is not set, it prompts the user to set it.
 * If the admin hash is set, it opens a dialog to ask for the hash and compares it.
 * If the hash is correct, it returns true. Otherwise, it returns false.
 *
 * @param authService - The authentication service.
 * @param firestore - The Firestore instance.
 * @param dialog - The MatDialog instance.
 * @param snack - The MatSnackBar instance.
 * @param router - The Router instance.
 * @param allowUndefined - (Optional) Whether to allow undefined admin hash. Defaults to false.
 * @returns A promise that resolves to a boolean indicating if the security check was successful.
 */
export const adminHashProtection = async ({
    authService,
    firestore,
    dialog,
    snack,
    router,
    allowUndefined,
}: {
    authService: AuthService;
    firestore: Firestore;
    dialog: MatDialog;
    snack: MatSnackBar;
    router: Router;
    allowUndefined?: boolean;
}) => {
    const firestoreId = authService.firestoreIdSignal$();
    if (!firestoreId) {
        return false;
    }
    // Check if the result is in the sessionStorage
    const cachedResult = sessionStorage.getItem(`slc-${firestoreId}`);
    if (cachedResult !== null) {
        // Parse the cached result and return it if it's not expired
        const { timestamp } = JSON.parse(cachedResult);
        const age = Date.now() - timestamp;
        const maxAge = 5 * 60 * 1000; // 5 minutes
        if (age < maxAge) {
            return true;
        }
    }

    const customerRef = doc(firestore, `customers/${firestoreId}`).withConverter(typeConverter<Customer>());
    const customer = await getDoc(customerRef);
    if (!customer.exists) {
        return false;
    }
    const hash = customer.data()?.adminHash;
    if (!hash && allowUndefined) {
        return true;
    }
    if (!hash) {
        // Prompt the user to set the password, set the action to navigate to the profile page
        snack
            .open('No extra security password set, please set your password on the profile page', 'Go to Profile', { duration: 5000 })
            .onAction()
            .pipe(take(1))
            .subscribe(() => router.navigate(['/profile']));
        return false;
    }
    // Open dialog to ask for hash and compare
    const dialogRef = dialog.open(AdminHashDialogComponent);
    dialogRef.componentInstance.checkWith = hash;
    const result: boolean | undefined = await dialogRef.afterClosed().toPromise();
    if (!result) {
        return false;
    }
    // Cache the result in the sessionStorage
    sessionStorage.setItem(`slc-${firestoreId}`, JSON.stringify({ timestamp: Date.now() }));
    return true;
};

/**
 * Guard function that checks for a referral code and reroutes to the invoices page if a referral code is present.
 *
 * @param _route - The activated route snapshot.
 * @param _state - The router state snapshot.
 * @returns A promise that resolves to `true` if the navigation should continue.
 */
export const referralRerouteToInvoices: CanActivateFn = async (_route, _state) => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const referralCode = await firstValueFrom(authService.referralCode$);
    if (referralCode) {
        router.navigate(['/invoices']);
    }
    return true;
};
