import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { REGION } from '@angular/fire/compat/functions';
import { BUCKET, USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { connectFirestoreEmulator, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import * as Sentry from '@sentry/angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { QRCodeModule } from 'angularx-qrcode';
import moment from 'moment';
import 'moment/locale/nl';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { routes } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

if (!isDevMode() && environment.production) {
    Sentry.init({
        dsn: 'https://019dae6b98cadb11841df87bd351e7ac@o4505919902318592.ingest.sentry.io/4505919903694848',
        integrations: [
            // Registers and configures the Tracing integration,
            // which automatically instruments your application to monitor its
            // performance, including custom Angular routing instrumentation
            Sentry.browserTracingIntegration(),
            // Registers the Replay integration,
            // which automatically captures Session Replays
            Sentry.replayIntegration(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay 0% of all normal sessions.
        // 100% for sessions with an error.
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}
// Register the locale data, the app is in english but the dates are in common dutch format.
registerLocaleData(localeNL, 'nl-NL');

bootstrapApplication(AppComponent, {
    providers: [
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            }
            return auth;
        }),
        importProvidersFrom(QRCodeModule, CalendarModule.forRoot({ provide: DateAdapter, useFactory: () => adapterFactory(moment) })),
        provideAnalytics(() => getAnalytics()),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            const firestore = getFirestore();
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideFunctions(() => {
            const functions = getFunctions(getApp(), 'europe-west1');
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            return functions;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        provideAnimations(),
        provideRouter(routes),
        provideMomentDateAdapter(),
        provideServiceWorker('ngsw-worker.js', { enabled: !isDevMode(), registrationStrategy: 'registerWhenStable:30000' }),
        provideCharts(withDefaultRegisterables()),
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
        //
        // TODO: Delete below when not using angularfire/compat anymore. see: https://github.com/angular/angularfire/blob/master/docs/compat/emulators/emulators.md
        //
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
        { provide: USE_STORAGE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9199] : undefined },
        //
        { provide: BUCKET, useValue: environment.firebase.storageBucket },
        { provide: REGION, useValue: 'europe-west1' },
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
    ],
}).catch(err => console.error(err));
